<template>
  <div class="min-h-[70vh] flex items-center justify-center">
    <div class="max-w-[50rem] flex flex-col mx-auto size-full bg-transparent">
      <main id="content">
        <div class="text-center py-10 px-4 sm:px-6 lg:px-8">
          <h1
            class="block text-7xl font-bold text-gray-800 sm:text-9xl dark:text-white"
          >
            401
          </h1>
          <p class="mt-3 text-gray-600 dark:text-neutral-400">
            Unauthorized Access
          </p>
          <p class="text-gray-600 dark:text-neutral-400">
            You are not authorized to access this page.
          </p>
          <div
            class="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3"
          >
            <router-link
              to="/"
              class="w-full sm:w-auto py-2 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-md focus:outline-none focus:from-violet-600 focus:to-blue-600 disabled:opacity-50 disabled:pointer-events-none"
              tag="a"
            >
              <svg
                class="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m15 18-6-6 6-6" />
              </svg>
              Back to home
            </router-link>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "UnauthorizedAccessView",
};
</script>
