<template>
  <div id="app" class="max-w-8xl font-inter">
    <!-- Authenticated-->
    <div
      class="antialiased dark:bg-gray-900"
      v-if="
        $store.getters['auth/authenticated'] &&
        $route.meta.authenticated == true
      "
    >
      <!-- Navbar -->
      <ApplicationNavSection></ApplicationNavSection>

      <!-- Primary Sidebar-->
      <PrimarySidebarSection></PrimarySidebarSection>

      <!-- View Content -->
      <main class="dark:bg-gray-900 p-4 md:ml-64 lg:mr-16 min-h-full pt-20">
        <router-view></router-view>
        <!-- Footer -->
        <ApplicationFooterSection class="pt-4"></ApplicationFooterSection>
      </main>

      <!-- Secondary Sidebar-->
      <SecondarySidebarSection></SecondarySidebarSection>
    </div>
    <!-- Unauthenticated-->
    <div
      v-if="
        !$store.getters['auth/authenticated'] ||
        $route.meta.authenticated == false
      "
      class="before:absolute before:top-0 before:start-1/2 before:bg-[url('https://preline.co/assets/svg/examples/polygon-bg-element.svg')] dark:before:bg-[url('https://preline.co/assets/svg/examples-dark/polygon-bg-element.svg')] before:bg-no-repeat before:bg-top before:bg-cover before:size-full before:-z-[1] before:transform before:-translate-x-1/2"
    >
      <!-- Navbars -->
      <NavSection class="px-4 mx-auto lg:px-12"></NavSection>
      <!-- Views Content -->
      <router-view></router-view>
      <!-- Footer -->
      <FooterSection></FooterSection>
    </div>
  </div>
</template>
<script>
import Pusher from "pusher-js";

import { mapState, mapActions } from "vuex";
import { StytchB2BHeadlessClient } from "@stytch/vanilla-js/b2b/headless";

import NavSection from "./components/navbars/NavSection.vue";
import ApplicationNavSection from "./components/navbars/ApplicationNavSection.vue";

import FooterSection from "./components/footers/FooterSection.vue";
import ApplicationFooterSection from "./components/footers/ApplicationFooterSection.vue";

import PrimarySidebarSection from "./components/sidebars/PrimarySidebarSection.vue";
import SecondarySidebarSection from "./components/sidebars/SecondarySidebarSection.vue";

import { getCookie } from "./utils";

Pusher.logToConsole = true;

export default {
  name: "App",
  components: {
    NavSection,
    ApplicationNavSection,
    PrimarySidebarSection,
    SecondarySidebarSection,
    FooterSection,
    ApplicationFooterSection,
  },
  computed: {
    ...mapState("auth", ["user", "stytch_pk"]),
  },
  updated() {
    // Preline Init on Update
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  },
  mounted: async function () {
    // Preline Init
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }

    const stytch = new StytchB2BHeadlessClient(this.stytch_pk);

    // Stytch Auth Logic
    const session = stytch.session.getSync();
    console.log(session);

    if (session == null) {
      this.logout();
    } else {
      await this.setAuthenticated();
      if (session.organization_id != this.$store.getters["auth/organization"]) {
        this.$store.commit(
          "auth/setOrganization",
          stytch.organization.getSync()
        );
        this.$store.commit("auth/setUser", stytch.member.getSync());
      }
      if (this.$route.name == "login") {
        this.$router.push("/");
      }
    }

    stytch.session.onChange(async (session) => {
      console.log("Session found: ", session);

      if (session == null) {
        this.logout();
        if (this.$route.name !== "login") {
          this.$router.push("/login");
        }
      } else {
        await this.setAuthenticated();
        if (
          session.organization_id != this.$store.getters["auth/organization"]
        ) {
          this.$store.commit(
            "auth/setOrganization",
            stytch.organization.getSync()
          );
          this.$store.commit("auth/setUser", stytch.member.getSync());
        }
        if (this.$route.name == "login") {
          this.$router.push("/");
        }
      }
    });
  },
  methods: {
    ...mapActions("auth", ["logout", "setAuthenticated", "getUser"]),
    connect: function () {
      // Init Pusher
      var pusher = new Pusher(this.$store.state.pusher_pk, {
        cluster: "us2",
        authEndpoint: this.$store.state.root_url + "/api/v1/users/pusher/auth",
        transport: "ajax",
        auth: {
          headers: {
            stytch_auth: getCookie("stytch_session"),
          },
        },
      });

      // Set Pusher Connection Bindings
      pusher.connection.bind("connected", () => {
        console.log("Connected.");
      });
      pusher.connection.bind("connecting", () => {
        console.log("Connecting...");
      });
      pusher.connection.bind("disconnected", () => {
        console.log("Disconnected");
      });
      pusher.connection.bind("unavailable", () => {
        console.log("Unavailable");
      });

      // Subscribe to Pusher Channel
      var channel = pusher.subscribe("private-" + this.user.stytch_user_id);

      // Subscribe to Forced Refreshes
      channel.bind("REFRESH", (data) => {
        console.log(data);
        location.reload(true); //Force refresh page
      });
    },
  },
};
</script>

<style></style>
