<template>
  <div>
    <footer
      class="mt-auto w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-12 mx-auto"
    >
      <div class="pt-5 mt-5">
        <div class="flex flex-wrap justify-between items-center gap-3">
          <div class="flex flex-wrap items-center gap-3">
            <div class="space-x-4 text-sm">
              <router-link
                tag="a"
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                to="/terms"
                >Terms</router-link
              >
              <router-link
                tag="a"
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                to="/privacy"
                >Privacy</router-link
              >
              <a
                href="https://docs.flux-os.com"
                target="_blank"
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                >Developers</a
              >
            </div>
          </div>

          <div class="flex flex-wrap justify-between items-center gap-3">
            <!-- Social Brands -->
            <div class="space-x-4">
              <a
                class="inline-block text-gray-500 hover:text-gray-800 focus:outline-none focus:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                href="https://github.com/2632202-Alberta-Inc"
                target="_blank"
              >
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"
                  />
                </svg>
              </a>
              <a
                class="inline-block text-gray-500 hover:text-gray-800 focus:outline-none focus:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                href="https://www.linkedin.com/company/flux-os"
                target="_blank"
              >
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"
                  />
                  <rect fill="currentColor" width="4" height="12" x="2" y="9" />
                  <circle fill="currentColor" cx="4" cy="4" r="2" />
                </svg>
              </a>
            </div>
            <!-- End Social Brands -->
          </div>
          <!-- End Col -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterMainSection",
};
</script>
