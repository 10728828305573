import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

// Landing Views
import HomeView from "../views/landing/HomeView.vue";

// Main Views
import AppView from "../views/main/AppView.vue";

// Authentication Views
import LoginView from "../views/auth/LoginView.vue";
import LogoutView from "../views/auth/LogoutView.vue";

// Legal Views
import TermsView from "../views/legal/TermsView.vue";
import PrivacyView from "../views/legal/PrivacyView.vue";

// Error Views
import PageNotFoundView from "../views/errors/PageNotFoundView.vue";
import InternalError from "../views/errors/InternalErrorView.vue";
import UnauthorizedAccessView from "../views/errors/UnauthorizedAccessView.vue";

Vue.use(VueRouter);

const routes = [
  // Landing Views
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      authenticated: false,
    },
  },
  // Auth Pages
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Get Started",
      authenticated: false,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
    meta: {
      title: "Logging out...",
      authenticated: false,
    },
  },
  // Legal Pages
  {
    path: "/terms",
    name: "terms",
    component: TermsView,
    meta: {
      title: "Terms of Use",
      authenticated: false,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyView,
    meta: {
      title: "Privacy Policy",
      authenticated: false,
    },
  },
  // Main Views
  {
    path: "/app",
    name: "app",
    children: [
      {
        path: "",
        name: "app-home",
        component: AppView,
        meta: {
          title: "Dashboard",
          authenticated: true,
        },
      },
    ],
  },
  // Error Pages
  {
    path: "/error",
    name: "500",
    component: InternalError,
    meta: {
      title: "Internal Error",
      authenticated: false,
    },
  },
  {
    path: "/unauthorized",
    name: "401",
    component: UnauthorizedAccessView,
    meta: {
      title: "Unauthorized Access",
      authenticated: false,
    },
  },
  {
    path: "*",
    name: "404",
    component: PageNotFoundView,
    meta: {
      title: "Page Not Found",
      authenticated: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(from);
  console.log(to);
  if (to.meta.authenticated == true) {
    const authenticated = store.getters["auth/authenticated"];
    if (authenticated == false) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    //Set Title Variable
    var title = to.meta.title + " | Flux OS";

    //Set Document Title
    document.title = title;
  });
});

export default router;
