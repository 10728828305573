var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-8xl font-inter",attrs:{"id":"app"}},[(
      _vm.$store.getters['auth/authenticated'] &&
      _vm.$route.meta.authenticated == true
    )?_c('div',{staticClass:"antialiased dark:bg-gray-900"},[_c('ApplicationNavSection'),_c('PrimarySidebarSection'),_c('main',{staticClass:"dark:bg-gray-900 p-4 md:ml-64 lg:mr-16 min-h-full pt-20"},[_c('router-view'),_c('ApplicationFooterSection',{staticClass:"pt-4"})],1),_c('SecondarySidebarSection')],1):_vm._e(),(
      !_vm.$store.getters['auth/authenticated'] ||
      _vm.$route.meta.authenticated == false
    )?_c('div',{staticClass:"before:absolute before:top-0 before:start-1/2 before:bg-[url('https://preline.co/assets/svg/examples/polygon-bg-element.svg')] dark:before:bg-[url('https://preline.co/assets/svg/examples-dark/polygon-bg-element.svg')] before:bg-no-repeat before:bg-top before:bg-cover before:size-full before:-z-[1] before:transform before:-translate-x-1/2"},[_c('NavSection',{staticClass:"px-4 mx-auto lg:px-12"}),_c('router-view'),_c('FooterSection')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }