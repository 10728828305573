import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "preline/dist/preline";

import "./middleware";
import "./assets/css/index.css";

import axios from "axios";
import lodash from "lodash";
import VueLodash from "vue-lodash";

Vue.use(VueLodash, { lodash: lodash });

axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
